<template>
  <div class="oboarding">
    <div class="home">
      <LogoHeader :is-logged-in="isLoggedIn" />
      <router-view />
      <Footer :white-footer="true" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LogoHeader from "@/components/organisms/LogoHeader.vue";
import Footer from "@/components/organisms/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "OnBoarding",
  components: {
    LogoHeader,
    Footer,
  },
  props: {
    isLoggedIn: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>
.home {
  height: 100vh;
  overflow: auto;
  font-weight: 400;
  line-height: 2em;

  background: linear-gradient(-15deg, $placeholder1 50%, $secondary3 50%);
}
</style>
